<div class="container">
  <div class="container-minus ion-activatable ripple-parent hover-effect" [ngClass]="{ inactive: product.inputQuantity === 0 || isLoading }">
    <p (click)="minus(product)" [attr.tappable]="product.inputQuantity > 0 && !isLoading ? null : ''">-</p>
    <ion-ripple-effect *ngIf="product.inputQuantity > 0 && !isLoading"></ion-ripple-effect>
  </div>
  <ion-input
    [(ngModel)]="quantity"
    type="tel"
    inputmode="numeric"
    (ionInput)="handleInputChange(product)"
    (ionFocus)="getFocus()"
    [disabled]="isLoading"
    [readonly]="cartIsRedeemable"
  >
  </ion-input>
  <div class="container-plus ion-activatable ripple-parent hover-effect" [ngClass]="{ inactive: isLoading }">
    <p (click)="plus(product)" [attr.tappable]="isLoading ? null : ''">+</p>
    <ion-ripple-effect *ngIf="!isLoading"></ion-ripple-effect>
  </div>
</div>
