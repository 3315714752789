<ion-footer class="ion-no-border">
  <div class="points" *ngIf="points">
    <p *ngIf="points">Você ganhará {{ points | number: "1.0" }} pontos Orbitta</p>
  </div>
  <div class="content">
    <div class="line">
      <p>{{ label }}</p>
      <p *ngIf="value">R$ {{ value / 100 | number: "1.2-2" }}</p>
    </div>
    <ion-button expand="block" fill="solid" color="primary" (click)="clickBtn()" [disabled]="disabled">{{ labelButton }} </ion-button>
  </div>
</ion-footer>
