<ion-header class="ion-no-border" [ngClass]="{ primary: primary, 'my-ios': shared.ios }">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="goBack()" *ngIf="showBack">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
      <div *ngIf="!showBack" style="min-width: 40px">&nbsp;</div>
    </ion-buttons>
    <div class="container-title">
      <h1>
        <span>{{ title }}</span>
      </h1>
    </div>
  </ion-toolbar>
</ion-header>
